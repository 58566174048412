import { useEffect, useRef } from "react"


export default ({content, customClass})=>{
    const ref = useRef(null);
    
    useEffect(()=>{
        // validate non null element
        if(ref.current == null) return;
        const slider = ref.current.children[1];
        // invert scroll y to x
        slider.addEventListener('wheel', e =>{
            if(slider.scrollLeft == 0 && e.deltaY < 0) return;
            if(slider.scrollLeft + slider.clientWidth >= slider.scrollWidth && e.deltaY > 0) return;
            e.preventDefault();
            const step = ref.current.classList.contains('full') ? 700: 300;
            // termporally disable snap scrolling
            slider.style.scrollSnapType = 'none';
            slider.scrollLeft += e.deltaY > 0 ? step: -step;
            // Re-enable snap scrolling
            setTimeout(() => {
                slider.style.scrollSnapType = 'x mandatory';
            }, 50);
        })
    },[]);

    const handleClick = amount =>{
        const slider = ref.current.children[1];
        const step = ref.current.classList.contains('full') ? 700: 300;
        slider.scrollLeft += (step * amount);
    }
    
    return (
        <section ref={ref} className={`${customClass} slider`}>
            <i className="material-symbols-outlined" onClick={e=> handleClick(-1)}>chevron_backward</i>
            {content}
            <i className="material-symbols-outlined" onClick={e=> handleClick(1)}>chevron_forward</i>
        </section>
    )
}